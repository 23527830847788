@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-bg: #2c3753;
  --sell-button-bg: "#d7276b";
  --sell-button-color: "#a02151";
  --buy-button-bg: "var(--color-green-2)";
  --buy-button-color: "#277E67";
  --bg-color-1: #21293c;
  --top-header-bg: var(--primary-bg);
  --tab-color: #1a2234;
  /* --sidebar-bg: #1c2333; */
  --sidebar-bg: var(--primary-bg);
  --bg-color-4: #1b212f;
  --bg-color-5: #273042;
  --bg-color-6: #252b3d;
  --bg-color-7: #242c3e;
  --bg-color-8: var(--primary-bg);
  --bg-color-9: #2a334d;
  --bg-color-10: #212a40;
  --bg-color-11: #2e3552;
  --bg-color-12: #415177;
  --bg-color-13: #485a84;
  --bg-color-14: #394768;
  --bg-color-right-sidebar-1: var(--bg-color-14);
  --bg-color-right-sidebar-2: var(--bg-color-10);
  --color-primary: #2294e6;
  --color-primary2: #3faaf7;
  --color-primary-3: #2f96f0;
  --color-primary-4: #63b7fb;
  --color-primary-5: #398fec;
  --color-primary-6: #4d9aee;
  --color-secondary: var(--bg-color-10);
  --color-tertiary: #244063;
  --color-white: #ffffff;
  --color-white-2: #c6c9cd;
  --color-white-3: #e8eef7;
  --color-black: #000000;
  --color-red: #ea4335;
  --color-red-2: #d7276b;
  --color-green: #04be7f;
  --color-green-2: #32a88a;
  --border-color-profile: #2f384b;
  --border-color-1: #29324b;
  --border-color-2: #222739;
  --button-color: #ffa665;
  --text-color: #7d8da1;
  --text-secondary: #abb8d4;
  --text-color-3: #8594b7;
  --text-color-4: #ffa25c;
  --appbar-icon-color: #94a2c1;
  --appbar-border-right: #414a6066;
  --scrollbar-color: #ffffff1a;
  --hover-trade-color: #434f75;
  --table-header-color: #41505e;
  --indicator-color: #2e3552;
  --indicator-hover-color: #434f75;
  --layout-color: #636d96;
  --layout-hover-color: #858eb3;
  --color-success: #5cbfa6;
  --input-border-color: var(--bg-color-13);
  --tab-hover-bg: #415177;
  --tab-hover-color: var(--color-white-3);
  --trade-bar-bg: var(--primary-bg);
  --trade-bar-hover-bg: #415177;
  --trade-bar-border-color: var(--bg-color-10);
}

* {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: var(--bg-color-1);
  overflow-y: hidden;
  overflow-x: hidden;
}

.appbar {
  position: absolute;
  background-color: var(--top-header-bg);
  height: 65px;
  top: 0;
  width: 100%;
}

.sidebar-left {
  position: absolute;
  background: var(--sidebar-bg);
  top: 65px;
  /* height: calc(100vh - 65px); */
  height: calc(100% - 65px);
  width: 90px;
  left: 0;
  box-sizing: border-box;
  z-index: 999;
  box-shadow: 5px 8px 6px -6px black;
}

/* new left sidebar content */

.left-sidebar-content {
  position: absolute;
  top: 0px;
  left: 90px !important;
  width: 350px;
  z-index: -1;
  background: var(--bg-color-right-sidebar-2);
  transition: left 0.5s ease-in-out;
  animation: hideSidebar 0.5s forwards;
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  -ms-transition: left 0.5s ease-in-out;
  -o-transition: left 0.5s ease-in-out;
  overflow: hidden;
  box-shadow: 5px 8px 6px -6px black;
}

.left-sidebar-content.show {
  left: 0px;
  animation: showSidebar 0.5s forwards;
}

@keyframes showSidebar {
  0% {
    transform: translateX(-350px);
    -webkit-transform: translateX(-350px);
    -moz-transform: translateX(-350px);
    -ms-transform: translateX(-350px);
    -o-transform: translateX(-350px);
  }

  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

.left-sidebar-content.hide-right {
  animation: hideSidebar 0.5s forwards;
  -webkit-animation: hideSidebar 0.5s forwards;
}

@keyframes hideSidebar {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-440px);
    -webkit-transform: translateX(-440px);
    -moz-transform: translateX(-440px);
    -ms-transform: translateX(-440px);
    -o-transform: translateX(-440px);
  }
}
/* Right side bar */
.sidebar-right {
  position: absolute;
  background: var(--sidebar-bg);
  top: 65px;
  /* height: calc(100vh - 65px); */
  height: 100%;
  width: 90px;
  right: 0;
}
.sidebar-right-content {
  position: absolute;
  background: var(--bg-color-right-sidebar-2);
  /* height: calc(100vh - 65px); */
  height: 100%;
  width: 360px;
  right: 90px;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  z-index: 999;
  /* box-shadow: 1px 0px 7px -6px #0c0c0c; */
  box-shadow: -5px 8px 6px -6px black;
}
.sidebar-right-content.open {
  transform: translateX(0);
}

.sidebar-right-content.close {
  transform: translateX(100%);
  pointer-events: none;
}
.main-container {
  position: absolute;
  background-color: var(--bg-color-6);
  top: 65px;
  right: 90px;
  left: 90px;
  bottom: 0;
  /* Add this line to set the bottom position */
  transition: right 0.3s ease-in-out;
  overflow: hidden;
  /* Add this line to hide the chart overflow */
}
.main-container-shifted {
  right: 0;
  transform: translateX(0%);
  transition: right 4s ease-in-out, transform 4s ease-in-out;
}
.main-container-shifted.open {
  right: 0;
  transform: translateX(-360px);
  transition: right 4s ease-in-out, transform 0.4s ease-in-out;
}
.closed {
  right: 0;
  transform: translateX(0%);
  transition: right 4s ease-in-out, transform 0.4s ease-in-out;
}
.main-content {
  width: 100%;
  position: absolute;
  background: var(--bg-color-7);
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-x: auto;
  overflow-y: hidden !important;
}

/* deposit page */
.payment-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.payment-btn .label {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--color-white-2);
}

.payment-btn img {
  max-width: 36px;
  max-height: 32px;
}

.payment-btn.selected {
  border-color: var(--color-primary);
}

.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  font-size: 0.5rem;
  color: #fff;
  background-color: var(--color-primary);
}

.payment-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.payment-amount .amount {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--color-white-2);
}

.payment-amount .bonus {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--color-white-2);
}

.payment-amount.selected {
  border-color: var(--color-primary);
}

.outlined-white {
  border-color: var(--color-white-2) !important;
}

/* language */
.lang-img {
  width: 23px;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  background: var(--bg-color-5) !important;
}
.css-oapmtd {
  background: var(--bg-color-5) !important;
}

/* apps content style */

.app-content {
  background: var(--bg-color-5);
}

/* added right sidebar scrollbar */
.scrollbar {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

#scrollbar1::-webkit-scrollbar {
  width: 10px;
}

#scrollbar1::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2);
}

#scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

/* added currency scrollbar */
.scrollbar2 {
  height: 490px;
  overflow-y: auto;
}

#scrollbarIndicator::-webkit-scrollbar {
  width: 8px;
}

#scrollbarIndicator::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2);
}

#scrollbarIndicator::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.css-1eteehc-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  height: "40px";
}

.css-1pmycv7-MuiPaper-root-MuiAccordion-root:before {
  height: 0px !important;
}

.no-payment-history-block {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, 50%);
}

.css-lytbfn-MuiTypography-root {
  color: var(--color-white) !important;
}

.list-button {
  padding: 5px;
}

.currency-icon-up {
  color: var(--color-green);
  float: right;
}

.currency-icon-down {
  color: var(--color-red);
  float: right;
}

.currency-icon-newtal {
  color: var(--color-white-2);
  float: right;
}

.chart_type_carousel {
  border-radius: "5px";
  height: "50px";
  width: "50px";
  border: "1px solid var(--color-primary)";
  justify-content: "center";
  align-items: "center";
  margin-left: "5px";
  margin-right: "5px";
  padding: "5px";
}

.relode {
  color: var(--color-white) !important;
  cursor: pointer;
}

.relode:hover {
  color: var(--color-green) !important;
}

/* edit profile */
.slide-from-right {
  animation: slideFromRight 0.3s ease-in-out;
  -webkit-animation: slideFromRight 0.3s ease-in-out;
}

.slide-from-left {
  animation: slideFromLeft 0.3s ease-in-out;
  -webkit-animation: slideFromLeft 0.3s ease-in-out;
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.cus-image-uploader {
  height: 200px;
  border: 2px dotted var(--bg-color-8);
}

/* left sidebar content scroll */
.lfc-scroll {
  height: calc(100vh - 140px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll::-webkit-scrollbar {
  width: 5px;
}
/* left sidebar trade journal scroll */
.lfc-scroll-journal {
  height: calc(100vh - 210px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll-journal::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-journal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-journal::-webkit-scrollbar {
  width: 5px;
}
/* right sidebar content scroll */
.lfc-scroll2 {
  height: calc(100vh - 127px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll2::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll2::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll2::-webkit-scrollbar {
  width: 5px;
}

/* left sidebar content scroll */
.lfc-scroll3 {
  height: calc(100vh - 238px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll3::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll3::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll3::-webkit-scrollbar {
  width: 5px;
}

/* right sidebar social content scroll */
.lfc-scroll-social {
  height: calc(100vh - 78px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll-social::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-social::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-social::-webkit-scrollbar {
  width: 5px;
}
/* Deals Scroll */
.lfc-scroll-deals {
  height: calc(100vh - 170px);
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

/* Change scrollbar track color */
.lfc-scroll-deals::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-deals::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-deals::-webkit-scrollbar {
  width: 5px;
}
/* Deals history scroll */
.lfc-scroll-history {
  height: calc(100vh - 135px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll-history::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-history::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-history::-webkit-scrollbar {
  width: 5px;
}

/* left sidebar content scroll */
.lfc-scroll-faq {
  height: calc(100vh - 244px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll-faq::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-faq::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-faq::-webkit-scrollbar {
  width: 5px;
}

/* left sidebar content terms and policy scroll */

.lfc-scroll-term-cond {
  height: calc(100vh - 206px);
  overflow-y: scroll;
  padding-right: 5px;
}

/* Change scrollbar track color */
.lfc-scroll-term-cond::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-term-cond::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-term-cond::-webkit-scrollbar {
  width: 5px;
}

/* Trade Result Scroll */

.lfc-scroll-trade-result {
  /* height: calc(100vh - 613px); */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-right: 5px; */
}

/* Change scrollbar track color */
.lfc-scroll-trade-result::-webkit-scrollbar-track {
  background-color: var(--bg-color-right-sidebar-2) !important;
}

/* Change scrollbar thumb color */
.lfc-scroll-trade-result::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--scrollbar-color);
}

.lfc-scroll-trade-result::-webkit-scrollbar {
  width: 5px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: var(--color-white) !important;
}

.cus-badge {
  margin-top: -20px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  padding: 10px 0;
}

.trade-bar {
  padding: 0 10px;
}

/* When save email & pass choose the input bg color white remove */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

/* open real account button style */
.open-real-btn {
  background-color: var(--color-success) !important;
  box-shadow: 1px 1px 10px #03a67000 !important;
  /* font-size: 14px !important; */
  text-transform: none !important;
  padding: 8px 11px !important;
  font-weight: 400 !important;
  color: var(--color-white) !important;
  margin-right: 42px !important;
}

.open-real-btn:hover {
  background-color: var(--color-success) !important;
  box-shadow: 1px 1px 10px var(--color-success) !important;
}

/* indicator cancel button style */
.cancel-btn {
  background-color: var(--bg-color-13) !important;
  text-transform: none !important;
  padding: 7px 45px !important;
  font-weight: 400 !important;
  color: var(--color-white) !important;
}

.cancel-btn:hover {
  background-color: var(--bg-color-13) !important;
  box-shadow: 1px 1px 10px var(--bg-color-13) !important;
}
.apply-btn {
  background-color: var(--color-primary-3) !important;
  text-transform: none !important;
  padding: 7px 45px !important;
  font-weight: 400 !important;
  color: var(--color-white) !important;
}

.apply-btn:hover {
  background-color: var(--color-primary-4) !important;
  box-shadow: 1px 1px 10px var(--color-primary-3) !important;
}

#avatar {
  width: 50px;
  height: 50px;
}

#avatar-id {
  font-size: 13px;
}

/* Authentication background */
.authentication-bg {
  width: 100%;
  height: 100vh;
  background-image: url(assets/auth/auth2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
/* About help page bg */
.bg-image-help {
  width: 100%;
  height: 300px;
  background-image: url(assets/about/bg-about-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#mainContainer {
  background: #2294e6;
  width: 100%;
  height: 100%;
}
.bg-image2 {
  background-image: url(assets/pass1.webp);
}
.backdrop {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.backdrop:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: -1;
}

.MuiLinearProgress-barColorSecondary {
  background-color: #d7276b !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #32a88a !important;
}

.MuiTab-root {
  font-weight: 300;
}

.itc-chart-container .loading {
  background-color: #000 !important; /* Set the desired background color */
}

.button_bar_off span {
  background-color: #1976d200 !important;
}
.button_bar_off .css-1714qv6-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1976d2 !important;
  border: 1px solid !important;
}

.container_grid {
  width: 361px !important;
  border: 1px solid #182031 !important;
  margin-left: -35px !important;
}

.grid_head {
  border-right: 1px solid #182031;
  height: 35px;
  text-align: center;
  background: #2c3753;
}
.grid_heads {
  text-align: center;
  background: #394768;
  justify-content: center;
}
.grid_head_typography {
  margin-top: 8px !important;
}
.grid_child {
  border-right: 1px solid #182031;
  border-top: 1px solid #182031;
  height: 35px;
  text-align: center;
  background: #394768;
  justify-content: center;
}

.grid_child_typography {
  margin-top: 8px !important;
}
.grid_child_typographys {
  margin-top: 5px !important;
  margin-left: 5px !important;
  height: 23px;
}

.grid_childs {
  background: #394768 !important;
  border-top: 1px solid #182031 !important;
}

.grid_childs_child {
  background: #2f96f021;
  width: auto;
  margin-left: 5px;
  border-left: 2px solid #2e90e6;
  border-radius: 4px;
}

.test_time {
  line-height: 25px !important;
}
/* Indicators style start here */
.tab-content {
  transition: height 0.3s ease;
  overflow: hidden;
}

.tab-content.expanded {
  height: auto;
}

.notistack-SnackbarContainer {
  margin-bottom: 108px;
}

/* --------- Login And Sign Up css Start here ------------ */

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --rotate: 0deg;
  --card-height: 65vh;
  --card-width: calc(var(--card-height) / 1.5);
  --gradient-angle: var(--rotate);
}

.card {
  background: #191c29;
  width: 390px;
  height: 600px;
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: rgb(88 199 250 / 0%);
  /* cursor: pointer; */
  color: #fff;
  inset: 0;
  margin: auto;
}

.card::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  background-image: -moz-linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  background-image: -o-linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -2%;
  animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: -webkit-linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  background-image: -moz-linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  background-image: -o-linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #2f96f0);
  opacity: 1;
  transition: opacity 0.5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

.image-container {
  text-align: center;
  margin-top: 1em;
}

.form-content {
  width: 360px;
  margin: 1em auto;
  padding: 3em 2em 0em 2em;
  background: transparent;
}

.group {
  position: relative;
  margin-bottom: 25px;
}

.input-field {
  font-size: 18px;
  padding: 10px 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  display: block;
  background: transparent;
  color: white;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #2f96f0;
}

.input-field:focus {
  outline: none;
}

/* Label */
.label-input {
  color: #2f96f0;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: all 0.2s ease;
}
/* active */

.input-field:focus ~ .label-input,
.input-field.used ~ .label-input {
  top: -20px;
  transform: scale(0.75);
  left: -2px;
  /* font-size: 14px; */
  color: #2f96f0;
}

/* Underline */

.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: transparent;
  transition: all 0.2s ease;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

/* active */

.input-field:focus ~ .bar:before,
.input-field:focus ~ .bar:after {
  width: 50%;
}

/* Highlight */

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active */
.input-field:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}
/* Animations */
@keyframes inputHighlighter {
  from {
    background: #4a89dc;
  }
  to {
    width: 0;
    background: transparent;
  }
}
/* Button */
.button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  margin: 0.3em 0 1em 0;
  width: 100%;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  letter-spacing: 1px;
  background: transparent;
  border: 0;
  border-bottom: 2px solid #2f96f0;
  cursor: pointer;
  transition: all 0.15s ease;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  touch-action: manipulation;
}
.button:hover,
.button:focus {
  background-color: #2f96f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}
.button:hover {
  transform: translateY(-1px);
}
.button:active {
  background-color: #2f96f0;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  /* transform: translateY(0); */
}
.button:focus {
  outline: 0;
}
/* Button modifiers */
.buttonBlue {
  background: #2f96f0;
  text-shadow: 1px 1px 0 rgba(39, 110, 204, 0.5);
}
/* Ripples container */
.ripples {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}
/* Ripples circle */
.ripplesCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
}
.ripples.is-active .ripplesCircle {
  animation: ripples 0.4s ease-in;
}
/* Ripples animation */
@keyframes ripples {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}

.input-custom {
  width: 26px;
  height: 26px;
  background: #2e3552;
  border: none;
}

/* media query for responsive */
@media (min-width: 950px) and (max-width: 1140px) {
}

@media (min-width: 641px) and (max-width: 1024px) {
  .css-179gx04-MuiToolbar-root {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .lang-img {
    width: 18px;
  }

  #btn-responsive {
    font-size: 10px;
  }

  #avatar {
    width: 30px;
    height: 30px;
  }

  #avatar-id {
    font-size: 8px;
  }

  /* #open-real-btn {
    font-size: 9px;
    padding: 6px;
  } */
  /* trade bar button responsive */
  .trade-bar-btn {
    padding: 5px 9px !important;
  }
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}
/* Media query for desktop & mobile device */
@media only screen and (max-width: 640px) {
  .mobile {
    display: block;
    width: 100%;
    height: 100%;
  }
  .bottom-spacing {
    margin-bottom: 80px !important;
  }
  #app-content-image-style {
    height: 100% !important;
  }
  .lfc-scroll {
    height: 100vh;
    overflow-y: scroll;
    padding-right: 5px;
  }

  .desktop {
    display: none;
  }
}
